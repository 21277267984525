<!--
 * @Description: 供应商评价采购
 * @Author: zhang zhen
 * @Date: 2023-03-15 16:02:20
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-31 10:07:24
 * @FilePath: /page-sass/src/views/requirement/appraiseManagement/appraisePurchaser.vue
-->
<template>
  <div>
    <div class="content-view">
      <h3 class="info-title">服务评价</h3>
      <div class="content-view-review">
        <div class="goods-info-review">
          <div class="star-info">
            <span class="label">满意度</span>
            <a-rate v-model="info.wholeEvaluationStar" :tooltips="desc" />
          </div>
          <div class="star-info">
            <span class="label">其他评价(可多选)</span>
          </div>
          <div class="tagList">
            <div class="tagList-item" :class="chooseItems.includes(i.dictKey) && 'active'" @click="handleChooseItem(i)"
              v-for="i in tagList" :key="i.dictKey">{{ i.dictValue || '' }}</div>
          </div>
          <div class="text-area">
            <a-textarea :autosize="{ minRows: 6, maxRows: 8 }" :maxLength="500" placeholder="谈谈您的建议,我们会努力改进~"
              allow-clear v-model="info.content" />
            <span class="number-info">{{ info.content.length }}/500</span>
          </div>
          <div class="cover-box">
            <uploadFile class="uploader" :number="8" :uploadFileType="'image'" :defaultParams="{
              dir: 'reviewFile',
            }" v-model="info.reviewFileList" />
            <a-checkbox :checked="info.anonymousFlag" @change="handleNoName"> 匿名评价 </a-checkbox>
          </div>
        </div>
      </div>
      <div class="options">
        <a-button style="width: 120px;height:38px;font-weight: 500;color: #262626;" @click="handleCancel">取消</a-button>
        <a-button type="primary" style="width: 120px;height:38px;font-weight: 500;" :loading="loading"
          @click="handleAppraise">提交评价</a-button>
      </div>
    </div>
    <a-modal title="温馨提示" :visible="visible" centered :width="400" @ok="handleCancelInfo" @cancel="handleCancelInfo">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>请完善评价</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import uploadFile from '@/components/plugins/smallUpload.vue'
import { postAction, getAction } from '@/api/manage'
import { mapGetters } from 'vuex'
export default {
  name: 'appraisePurchaser',
  components: {
    uploadFile
  },
  data() {
    return {
      visible: false,
      loading: false,
      desc: ['很不满意', '不满意', '一般', '满意', '非常满意'],
      chooseItems: [],
      tagList: [

      ],
      info: {
        anonymousFlag: true,
        qualityStars: 0,
        payStars: 0,
        serviceStars: 0,
        content: '',
        reviewFileList: []
      },
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 16
      },
      rateObject: {
        1: '非常差',
        2: '差',
        3: '一般',
        4: '满意',
        5: '非常满意'
      },
      dataSource: [],
      sorter: {
        userType: this.setUserInfo().tradeIdentity
      },
      columns: [
        {
          title: '公司',
          dataIndex: 'name',
          width: '25%',
          align: 'center',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '评价',
          dataIndex: 'age',
          width: '75%',
          align: 'center',
          scopedSlots: { customRender: 'age' }
        }
      ],
      url: {
        list: '/order/order/buyer'
      },
      disableMixinCreated: true
    }
  },
  created() {
    const { orderNo } = this.$route.query
    orderNo && (this.sorter.orderNo = orderNo)
    this.handleLoadData(1)
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleChooseItem(i) {
      if (this.chooseItems.includes(i.dictKey)) {
        this.chooseItems = this.chooseItems.filter(item => item != i.dictKey)
      } else {
        this.chooseItems.push(i.dictKey)
      }
    },
    // 评价
    handleAppraise() {
      const { wholeEvaluationStar, content, reviewFileList, anonymousFlag } = this.info
      if (!wholeEvaluationStar) return (this.visible = true)
      let fileList = reviewFileList && reviewFileList.map(i => i.url) || []
      let evaluationTags = []
      this.tagList.map(i => {
        const { dictValue, dictKey, dictNo } = i;
        if (this.chooseItems.includes(dictKey)) {
          evaluationTags.push({
            tagKey: dictKey,
            tagName: dictValue,
            tagNo: dictNo
          })
        }
      })
      this.loading = true;
      postAction('/order/evaluation/add', {
        businessId: this.setUserInfo().businessId,
        orderNo: this.sorter.orderNo,
        wholeEvaluationStar,
        content,
        evaluationTags,
        anonymousFlag: anonymousFlag ? '1' : '0',
        attachments: fileList,
        userId: this.setUserInfo().userId,
        evaluationType: '1',
        deleteFlag: 0
      }).then(res => {
        const { success, message } = res
        this.loading = false
        if (success) {
          // this.$message.success(message)
          this.$router.go(-1)
        } else {
          this.$message.warning(message)
        }
      }).catch(err => {
        this.loading = false
      })
    },
    handleLoadData() {
      getAction(this.url.list, { ...this.sorter }).then(res => {
        const { success, message, data } = res
        if (success && data) {
          this.dataSource = [data]
        } else {
          this.$message.warning(message)
        }
      })
      //'1521'
      getAction('/basic/dict/queryByDictNo', { dictNo: '1521' }).then((res) => {
        if (res.success) {
          this.tagList = res.data
        }
      })
    },
    handleNoName(e) {
      this.info.anonymousFlag = e.target.checked
    },
    handleCancel() {
      this.$router.go(-1)
    },
    handleCancelInfo() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.content-view {
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  padding: 25px 25px 45px;

  &.last {
    margin-top: 15px;
    padding: 25px;
  }

  &-header {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    margin-bottom: 24px;

    span.title {
      margin-right: 20px;
      color: #131212;
      font-size: 16px;
      font-weight: 500;
    }

    .ant-checkbox-wrapper {
      color: #131212;
    }
  }

  &-review {
    display: flex;

    .goods-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 120px;
      margin-right: 15px;

      .cover-box {
        background-color: #fff;
        width: 120px;
        height: 120px;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 8px;
      }

      .goods-desc {
        color: #225588;
        font-size: 12px;
        line-height: 19px;
        white-space: pre-wrap;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }

    .goods-info-review {
      width: 640px;
    }
  }
}

.star-info {
  display: flex;
  align-items: center;
  line-height: 24px;
  margin-bottom: 15px;

  .label {
    color: #131212;
    margin-right: 20px;
  }
}

.text-area {
  position: relative;
  margin-bottom: 15px;

  .number-info {
    position: absolute;
    right: 8px;
    bottom: 8px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.options {
  text-align: center;
  margin-top: 30px;

  .ant-btn {
    &+.ant-btn {
      margin-left: 8px;
    }
  }
}

.info-card {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 18px 18px;
  margin-bottom: 14px;

  &-item {
    padding: 4px 8px 4px 8px;
    height: 30px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    color: #262626;
    line-height: 20px;
    cursor: pointer;
    font-size: 14px;
    background-color: #fff;

    &.active {
      border-color: #FF6E2D;
      color: #FF6E2D;
      background-color: #FFF2EA;
    }
  }
}
.cover-box {
  display: flex;
  justify-content: space-between;
  .uploader {
    flex: 1 0 0;
    min-width: 0;
    margin-right: 8px;
  }
}
</style>
